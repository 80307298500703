import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import jwtDecode from 'jwt-decode'


class ApiError extends Error {
  constructor(message: string) {
    super(message || "unknown_error")
    this.name = "ApiError"
  }
}


interface State {
  attemptAuthStatus: string,
  attemptSighUpStatus: string,
  attemptReinitPasswordStatus: string,
  user?: {
    jwt: string,
    email: string,
    secondFactor?: string,
    iat?: string,
  },
  pins?: {
    [email: string]: {
      pin: string,
      wrongTriesLeft: number,
    },
  },
}


const initialState:State = {
  attemptAuthStatus: "idle",
  attemptSighUpStatus: "idle",
  attemptReinitPasswordStatus: "idle",
  pins: {},
}


/**
 * Auth user with email and password.
 */
export const attemptAuth = createAsyncThunk(
  'auth/attemptAuth',
  async (payload: { email: string, password: string }, { dispatch }) => {

    const response = await fetch(process.env.REACT_APP_FIDO_BASE_URL + '/user/login', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(payload),
    })

    if (!response.ok) {
      const reason = (await response.json()).errorMessage || "unknown_error"
      throw new ApiError(reason)
    }

    const body = await response.json()

    dispatch(setJWT(body.jwt))
  }
)


/**
 * Auth user with email and password.
 */
export const attemptSighUp = createAsyncThunk(
  'auth/attemptSighUp',
  async (payload: { email: string, password: string }, { dispatch }) => {

    const response = await fetch(process.env.REACT_APP_FIDO_BASE_URL + '/user/create', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(payload),
    })

    if (!response.ok) {
      const reason = (await response.json()).errorMessage || "unknown_error"
      throw new ApiError(reason)
    }

    const body = await response.json()

    dispatch(setJWT(body.jwt))
  }
)


export const attemptAuthPin = createAsyncThunk(
  'auth/attemptLoginPin',
  async ({ pin }: { pin: string }, { dispatch, getState }) => {
    const { auth } = getState() as { auth: { user: { email: string, jwt: string }, pin: string }}

    const response = await fetch(process.env.REACT_APP_RELAY_BASE_URL + '/login-pin', {
      method: 'POST',
      headers: { 
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${auth.user.jwt}`,
      },
    })

    if (!response.ok) {
      const reason = (await response.json()).reason || "unknown_error"
      throw new ApiError(reason)
    }

    const body = await response.json()
    dispatch(authSlice.actions.setPin({ email: auth.user.email, pin }))
    dispatch(setJWT(body.token))
  }
)


export const attemptLogoutPin = createAsyncThunk(
  'auth/attemptLogoutPin',
  async (payload, { dispatch, getState }) => {
    const { auth } = getState() as { auth: { user: { jwt: string }, pin: string }}

    const response = await fetch(process.env.REACT_APP_RELAY_BASE_URL + '/logout-pin', {
      method: 'POST',
      headers: { 
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${auth.user.jwt}`,
      },
    })

    if (!response.ok) {
      const reason = (await response.json()).reason || "unknown_error"
      throw new ApiError(reason)
    }

    const body = await response.json()
    dispatch(setJWT(body.token))
  }
)


export const attemptReinitPassword = createAsyncThunk(
  'auth/attemptReinitPassword',
  async (payload: { email: string }) => {

    const response = await fetch(process.env.REACT_APP_RELAY_BASE_URL + '/start-reinit-password', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(payload),
    })

    if (!response.ok) {
      const reason = (await response.json()).reason || "unknown_error"
      throw new ApiError(reason)
    }
  }
)


const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setJWT: (state, action: PayloadAction<string>) => {
      const jwt = action.payload
      const { email, secondFactor, iat }: { email: string, secondFactor?: string, iat?: string, issuer?: string } = jwtDecode(jwt)
      if (!email) {
        throw new Error('Invalid JWT')
      }
      
      state.user = {
        jwt,
        email,
        secondFactor,
        iat,
      }
    },
    idleAttemptAuthStatus: (state) => {
      state.attemptAuthStatus = "idle"
    },
    idleAttemptSighUpStatus: (state) => {
      state.attemptSighUpStatus = "idle"
    },
    idleAttemptReinitPasswordStatus: (state) => {
      state.attemptReinitPasswordStatus = "idle"
    },
    logoutJWT: (state) => {
      state.user = undefined
    },
    setPin: (state, { payload }: PayloadAction<{ email: string, pin: string }>) => {
      if (!state.pins) {
        state.pins = {}
      }
      state.pins[payload.email] = { pin: payload.pin, wrongTriesLeft: 3 }
    },
    forgetPin: (state, { payload }: PayloadAction<string>) => {
      if (!state.pins) {
        state.pins = {}
      }
      state.user = undefined
      delete state.pins[payload]
    },
    wrongPinEntered: (state, { payload }: PayloadAction<string>) => {
      if (!state.pins) {
        state.pins = {}
      }
      state.pins[payload].wrongTriesLeft--
      if (state.pins[payload].wrongTriesLeft === 0) {
        state.user = undefined
        delete state.pins[payload]
      }
    }
  },

  extraReducers: {
    [attemptAuth.pending.type]: (state, action) => {
      state.attemptAuthStatus = "loading"
    },
    [attemptAuth.fulfilled.type]: (state, action) => {
      state.attemptAuthStatus = "idle"
    },
    [attemptAuth.rejected.type]: (state, action) => {
      const error = action.error
      state.attemptAuthStatus = error.name === "ApiError" ? error.message : "unknown_error"
    },

    [attemptSighUp.pending.type]: (state, action) => {
      state.attemptSighUpStatus = "loading"
    },
    [attemptSighUp.fulfilled.type]: (state, action) => {
      state.attemptSighUpStatus = "idle"
    },
    [attemptSighUp.rejected.type]: (state, action) => {
      const error = action.error
      console.error(error)
      state.attemptSighUpStatus = error.name === "ApiError" ? error.message : "unknown_error"
    },

    [attemptReinitPassword.pending.type]: (state, action) => {
      state.attemptReinitPasswordStatus = "loading"
    },
    [attemptReinitPassword.fulfilled.type]: (state, action) => {
      state.attemptReinitPasswordStatus = "success"
    },
    [attemptReinitPassword.rejected.type]: (state, action) => {
      const error = action.error
      state.attemptReinitPasswordStatus = error.name === "ApiError" ? error.message : "unknown_error"
    },
  },
  
})


export default authSlice.reducer


export const {
  setJWT,
  idleAttemptAuthStatus,
  idleAttemptSighUpStatus,
  idleAttemptReinitPasswordStatus,
  logoutJWT,
  forgetPin,
  wrongPinEntered,
} = authSlice.actions
