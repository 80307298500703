import React from 'react'
import { useSelector } from "react-redux"
import { Navigate } from "react-router"
import { RootState } from "../../store"


function RequireSecondFactorAuthMiddleware({ children }: { children: any }) {
  const user = useSelector((state: RootState) => state.auth.user)
  
  if (!user || !user.secondFactor) {
    return <Navigate to="/second-factor" replace />
  }

  return children
}

export default RequireSecondFactorAuthMiddleware
