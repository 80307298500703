import React from 'react'
import Header from '../../components/Header/Header'
import { RiArrowGoBackLine } from 'react-icons/ri'
import { Link } from 'react-router-dom'
import './personal-information.scss'


function PersonalInformation() {
  return (
    <div className="personal_information_page">
      <Header leftItem={<Link to="/" style={{ display: "flex" }}><RiArrowGoBackLine size="3em" color="#ffffff" className="header-icon"/></Link>}/>
      <div className="background-gradient"></div>
    </div>
  )
}

export default PersonalInformation
