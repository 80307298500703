import React from 'react'
import { AppDispatch, RootState } from '../../../store'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Button } from 'antd'
import "./ValidationEmailStatus.scss"
import { attemptValidateData } from '../redux/applicationsSlice'


function ValidationEmailStatus() {
  const { t } = useTranslation('application')
  const dispatch:AppDispatch = useDispatch()
  const value = useSelector((state:RootState) => state.fields.email)
  const validatedEmails = useSelector((state:RootState) => state.applications.validatedEmails)


  function resendValidation() {
    if (value) {
      dispatch(attemptValidateData({ type: 'email', value }))
    }
  }


  if (!value) {
    return <div className="validation_email_status-feedback">
      {t("EMAIL_NONE")}
    </div>
  }

  switch (validatedEmails[value]) {
    case "pending":
      return (
        <div className="validation_email_status-feedback">
          {t("EMAIL_VALIDATION_PENDING")}
          <div className="text-align-center">
            <Button type="link" onClick={resendValidation}>{t('Resend validation link')}</Button>
          </div>
        </div>
      )

    case "validated":
      return <div className="validation_email_status-feedback">{t("Your email has been validated!")}</div>
  }

  return <div className="validation_email_status-feedback">{t("EMAIL_NOT_VALIDATED")}</div>
}


export default ValidationEmailStatus
