import React from 'react'
import { Drawer as AntdDrawer } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../store'
import { closeDrawer } from '../../appSlice'
import { LanguageSwitch } from '../../screens/LanguageSwitch/LanguageSwitch'
import "./AppDrawer.scss"


function Drawer() {
  const dispatch = useDispatch()
  const drawerOpened = useSelector((state:RootState) => state.app.drawerOpened)
  

  function onClose() {
    dispatch(closeDrawer())
  }


  return (
    <AntdDrawer className="app_drawer" title="Settings" placement="left" onClose={onClose} visible={drawerOpened}>
      <div className="app_drawer-language_container">
        <div className="mr-1">Language</div> <LanguageSwitch/>
      </div>
    </AntdDrawer>
  )
}

export default Drawer