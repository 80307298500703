import React, { useEffect } from 'react'
import { Button, notification, Tabs } from 'antd'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from '../../store'
import CreatePin from './CreatePin/CreatePin'
import { BsArrowLeftShort } from 'react-icons/bs'
import { logoutJWT } from '../Auth/redux/authSlice'
import AuthPin from './AuthPin/AuthPin'
import { useNavigate } from 'react-router'
import { IoMdFingerPrint } from 'react-icons/io'
import { attemptSecondFactorWebauthn, attemptWebauthnAuthenticate, idleWebauthnAuthenticateStatus } from '../../webauthn/webAuthnSlice'
import "./AuthCreateSecondFactor.scss"


const { TabPane } = Tabs


function AuthCreateSecondFactor() {
  const dispatch:AppDispatch = useDispatch()
  const { t } = useTranslation('authCreateSecondFactor')
  const user = useSelector((state:RootState) => state.auth.user)
  const storedPin = useSelector((state:RootState) => state.auth.pins?.[user?.email || ''])
  const webauthnAuthenticateStatus = useSelector((state:RootState) => state.webAuthn.webauthnAuthenticateStatus)
  const navigate = useNavigate()


  useEffect(() => {
    if (user && (user.secondFactor === "pin" || user.secondFactor === "webauthn")) {
      navigate('/')
    }
  }, [user])


  useEffect(() => {
    switch (webauthnAuthenticateStatus) {
      case "unknown_error":
        notification.open({
          type: 'error',
          message: t('Unexpected error'),
          description: t('An error occurred, please try later.'),
        })
        break
    }

    return () => {
      dispatch(idleWebauthnAuthenticateStatus())
    }
  }, [webauthnAuthenticateStatus])


  function webauthn() {
    if (user) {
      dispatch(attemptSecondFactorWebauthn({ username: user.email, jwt: user.jwt }))
    }
  }


  return (
    <div className="auth_create_second_factor_page">
      <div className="background-gradient"/>

      <div className="auth_create_second_factor_container">
        <div className="create_2fa_title">
          <div className="create_2fa-link_back" onClick={() => dispatch(logoutJWT())}>
            <BsArrowLeftShort size="1.4rem" />
          </div>
          <h2 className="connected_email">{user?.email}</h2>
          <div className="w-2rem"></div>
        </div>
        <h3 className="mb-1 text-align-center">{t('Chose a second factor auth')}</h3>

        <Tabs defaultActiveKey="1">
          <TabPane tab={t('Local PIN')} key="1">
            {storedPin 
              ? <AuthPin/>
              : <CreatePin/> 
            }
          </TabPane>

          <TabPane tab={t('Biometric')} key="2">
            <div className="feedback-text mb-1">Tap this button to authenticate with device facilities</div>
            <div className="text-align-center mb-1">
              <Button
                type="primary"
                htmlType="submit"
                shape="circle"
                className="platform-authenticator-button"
                icon={<IoMdFingerPrint size="4em" />}
                onClick={webauthn}
              />
            </div>
          </TabPane>
        </Tabs>
      </div>
    </div>
  )
}

export default AuthCreateSecondFactor