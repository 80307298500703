export async function checkPlatformAuthenticatorAvailable() {
  if (PublicKeyCredential && typeof PublicKeyCredential.isUserVerifyingPlatformAuthenticatorAvailable === "function") {
    try {
      return await PublicKeyCredential.isUserVerifyingPlatformAuthenticatorAvailable()
    } catch(e) { }
  }

  return false
}

export interface ServerPublicKeyCredentialCreationOptionsRequest {
  username: string,
  jwt?: string,
  displayName: string,
  authenticatorSelection: {
    requireResidentKey: boolean,
    userVerification: string,
    authenticatorAttachment: string,
  },
  attestation: string,
}

/**
 * Base64 url encodes an array buffer
 * @param {ArrayBuffer} arrayBuffer
 */
export function bufferToBase64url(buffer: any) {
  const byteView = new Uint8Array(buffer);
  let str = "";
  for (let i = 0; i < byteView.length; i++) {
    str += String.fromCharCode(byteView[i])
  }
  const base64String = btoa(str);
  const base64urlString = base64String.replace(/\+/g, "-").replace(/\//g, "_").replace(/=/g, "");
  return base64urlString;
}

/**
* Base64 url decode
* @param {String} base64url
*/
export function base64UrlDecode(base64url: string) {
  let input = base64url
    .replace(/-/g, "+")
    .replace(/_/g, "/")
  let diff = input.length % 4
  if (!diff) {
    while(diff) {
      input += '='
      diff--
    }
  }

  return Uint8Array.from(atob(input), c => c.charCodeAt(0))
}


export function removeEmpty(obj: any) {
  for (let key in obj) {
    if (obj[key] == null || obj[key] === "") {
      delete obj[key]
    } else if (typeof obj[key] === 'object') {
      removeEmpty(obj[key])
    }
  }
}


export function performMakeCredReq(makeCredReq: any) {
  makeCredReq.challenge = base64UrlDecode(makeCredReq.challenge)
  makeCredReq.user.id = base64UrlDecode(makeCredReq.user.id)

  // Base64url decoding of id in excludeCredentials
  if (makeCredReq.excludeCredentials instanceof Array) {
    for (let i of makeCredReq.excludeCredentials) {
      if ('id' in i) {
        i.id = base64UrlDecode(i.id)
      }
    }
  }

  delete makeCredReq.status
  delete makeCredReq.errorMessage
  // delete makeCredReq.authenticatorSelection

  removeEmpty(makeCredReq)
  console.log("Updating credentials ", makeCredReq)

  return makeCredReq
}


/**
 * Performs an HTTP POST operation
 * @param {string} endpoint endpoint URL
 * @param {any} object
 * @returns {Promise} Promise resolving to javascript object received back
 */
export function rest_post(endpoint: string, object: any) {
  return fetch(process.env.REACT_APP_FIDO_BASE_URL + endpoint, {
    method: "POST",
    body: JSON.stringify(object),
    headers: {
      "content-type": "application/json",
    },
    credentials: 'include',
  })
  .then(response => {
    return response.json()
  })
}


/**
* Calls the .create() webauthn APIs and sends returns to server
* @return {any} server response object
*/
export function createCredential(options: any) {
  if (!PublicKeyCredential || typeof PublicKeyCredential.isUserVerifyingPlatformAuthenticatorAvailable !== "function") {
    return Promise.reject("WebAuthn APIs are not available on this user agent.");
  }

  console.log('start create')
  return navigator.credentials.create({ publicKey: options })
      .then((rawAttestation: any) => {
          console.log("raw attestation", rawAttestation);

          let attestation = {
              rawId: bufferToBase64url(rawAttestation.rawId),
              id: bufferToBase64url(rawAttestation.rawId),
              response : {
                  clientDataJSON: bufferToBase64url(rawAttestation.response.clientDataJSON),
                  attestationObject: bufferToBase64url(rawAttestation.response.attestationObject),
                  transports: null,
              },
              type: rawAttestation.type,
              extensions: null,
          };

          if (rawAttestation.getClientExtensionResults) {
              attestation.extensions = rawAttestation.getClientExtensionResults();
          }

          // set transports if it is available
          if (typeof rawAttestation.response.getTransports === "function") {
              attestation.response.transports = rawAttestation.response.getTransports();
          }

          console.log("=== Attestation response ===");
          console.log("rawId (b64url)", attestation.rawId)
          console.log("id (b64url)", attestation.id);
          console.log("response.clientDataJSON (b64url)", attestation.response.clientDataJSON);
          console.log("response.attestationObject (b64url)", attestation.response.attestationObject);
          console.log("response.transports", attestation.response.transports);
          console.log("id", attestation.type);

          return rest_post("/attestation/result", attestation);
      })
      .catch(function(error) {
        console.log("create credential error", error)
          if (error == "AbortError") {
            console.info("Aborted by user")
          }
          return Promise.reject(error);
      })
      .then(response => {
          if (response.status !== 'ok') {
              return Promise.reject(response.errorMessage);
          } else {
              return Promise.resolve(response);
          }
      });
}

/**
 * Retrieves a reg challenge from the server
 * @returns {Promise} Promise resolving to a ArrayBuffer challenge
 */
export function getRegChallenge(serverPublicKeyCredentialCreationOptionsRequest: ServerPublicKeyCredentialCreationOptionsRequest) {
  return rest_post("/attestation/options", serverPublicKeyCredentialCreationOptionsRequest)
      .then(response => {
          console.log("Get reg challenge response", response)
          if (response.status !== 'ok') {
              return Promise.reject(response.errorMessage)
          } else {
              let createCredentialOptions = performMakeCredReq(response)
              return Promise.resolve(createCredentialOptions)
          }
      })
}



/**
 * Retrieves a auth challenge from the server
 * @returns {Promise} Promise resolving to a ArrayBuffer challenge
 */
export function getAuthChallenge(serverPublicKeyCredentialGetOptionsRequest: any) {
  console.log("Get auth challenge", serverPublicKeyCredentialGetOptionsRequest);
  return rest_post("/assertion/options", serverPublicKeyCredentialGetOptionsRequest)
      .then(response => {
          if (response.status !== 'ok') {
              return Promise.reject(response.errorMessage);
          } else {
              let getCredentialOptions = performGetCredReq(response);
              return Promise.resolve(getCredentialOptions);
          }
      })
}


function performGetCredReq (getCredReq: any) {
  getCredReq.challenge = base64UrlDecode(getCredReq.challenge)

  //Base64url decoding of id in allowCredentials
  if (getCredReq.allowCredentials instanceof Array) {
    for (let i of getCredReq.allowCredentials) {
      if ('id' in i) {
        i.id = base64UrlDecode(i.id)
      }
    }
  }

  delete getCredReq.status
  delete getCredReq.errorMessage

  removeEmpty(getCredReq)

  console.log("Updating credentials ", getCredReq)
  return getCredReq
}


/**
 * Calls the .get() API and sends result to server to verify
 * @return {any} server response object
 */
export function getAssertion(options: any) {
  if (!PublicKeyCredential) {
      return Promise.reject("WebAuthn APIs are not available on this user agent.");
  }

  return navigator.credentials.get({publicKey: options})
      .then((rawAssertion: any) => {
          console.log("raw assertion", rawAssertion);

          let assertion = {
              rawId: bufferToBase64url(rawAssertion.rawId),
              id: bufferToBase64url(rawAssertion.rawId),
              response: {
                clientDataJSON: bufferToBase64url(rawAssertion.response.clientDataJSON),
                userHandle: bufferToBase64url(rawAssertion.response.userHandle),
                signature: bufferToBase64url(rawAssertion.response.signature),
                authenticatorData: bufferToBase64url(rawAssertion.response.authenticatorData)
              },
              type: rawAssertion.type,
              extensions: null,
          }

          if (rawAssertion.getClientExtensionResults) {
              assertion.extensions = rawAssertion.getClientExtensionResults()
          }

          console.log("=== Assertion response ===");
          console.log("rawId (b64url)", assertion.rawId);
          console.log("id (b64url)", assertion.id);
          console.log("response.userHandle (b64url)", assertion.response.userHandle);
          console.log("response.authenticatorData (b64url)", assertion.response.authenticatorData);
          console.log("response.lientDataJSON", assertion.response.clientDataJSON);
          console.log("response.signature (b64url)", assertion.response.signature);
          console.log("id", assertion.type);

          return rest_post("/assertion/result", assertion);
      })
      .catch(function(error) {
        console.log("get assertion error", error);
        if (error == "AbortError") {
            console.info("Aborted by user");
        }
        return Promise.reject(error);
      })
      .then(response => {
          if (response.status !== 'ok') {
              return Promise.reject(response.errorMessage);
          } else {
              return Promise.resolve(response);
          }
      })
}
