import React, { useEffect, useState } from 'react'
import { notification } from 'antd'
import { BsBackspace } from 'react-icons/bs'
import { useDispatch } from 'react-redux'
import { AppDispatch } from '../../../store'
import PinFeedback from './PinFeedback'
import PinButton from './PinButton'
import { useTranslation } from 'react-i18next'
import { attemptAuthPin } from '../../Auth/redux/authSlice'
import './CreatePin.scss'


function CreatePin() {
  const { t } = useTranslation('createPin')
  const dispatch:AppDispatch = useDispatch()
  const [pin, setPin] = useState('')
  const [confirmPin, setConfirmPin] = useState('')


  useEffect(() => {
    if (pin.length === 6 && confirmPin.length === 6) {
      if (pin === confirmPin) {
        dispatch(attemptAuthPin({ pin }))
      } else {
        setPin('')
        setConfirmPin('')
        notification.open({
          type: 'error',
          message: t('Wrong PIN confirmation'),
          description: t('Confirmation PIN must be the same as original one.')
        })
      }
    }
  }, [pin, confirmPin])


  function addPinLetter(letter: string) {
    if (pin.length < 6) {
      setPin(pin + letter)
    }
    else if (confirmPin.length < 6) {
      setConfirmPin(confirmPin + letter)
    }
  }


  function eraseLastLetter() {
    if (confirmPin.length > 0) {
      setConfirmPin(confirmPin.slice(0, -1))
    } else if (pin.length > 0) {
      setPin(pin.slice(0, -1))
    }
  }


  return (
    <>
      <div className="feedback-text">{pin.length < 6 ? t("Create your PIN"): t("Confirm your PIN")}</div>

      <PinFeedback full={pin.length}/>
      <PinFeedback full={confirmPin.length}/>

      <div className="mt-2">
        <div className="text-align-center">
          <PinButton symbol="1" onClick={() => addPinLetter("1")}/>
          <PinButton symbol="2" onClick={() => addPinLetter("2")}/>
          <PinButton symbol="3" onClick={() => addPinLetter("3")}/>
        </div>
        <div className="text-align-center">
          <PinButton symbol="4" onClick={() => addPinLetter("4")}/>
          <PinButton symbol="5" onClick={() => addPinLetter("5")}/>
          <PinButton symbol="6" onClick={() => addPinLetter("6")}/>
        </div>
        <div className="text-align-center">
          <PinButton symbol="7" onClick={() => addPinLetter("7")}/>
          <PinButton symbol="8" onClick={() => addPinLetter("8")}/>
          <PinButton symbol="9" onClick={() => addPinLetter("9")}/>
        </div>
        <div className="text-align-center">
          <PinButton/>
          <PinButton symbol="0" onClick={() => addPinLetter("0")}/>
          <PinButton symbol={<BsBackspace/>} onClick={eraseLastLetter}/>
        </div>
      </div>
    </>
  )
}

export default CreatePin
