import React from 'react'
import Header from '../../components/Header/Header'
import { RiArrowGoBackLine } from 'react-icons/ri'
import { Link } from 'react-router-dom'
import { APPLICATIONS } from './apps'
import "./applications.scss"


function Applications() {

  return (
    <div className="applications-page">
      <Header leftItem={<Link to="/" style={{ display: "flex" }}><RiArrowGoBackLine size="3em" color="#ffffff" className="header-icon"/></Link>}/>
      <div className="background-gradient"></div>
      
      <main className="main">
        {Object.entries(APPLICATIONS).map(([id, app]) => {
          return (
            <Link to={'/applications/' + id} key={id} className="application">
              <div className="application-image"><img src={app.icon}/></div>
              <div className="application-text">{app.name}</div>
            </Link>
          )
        })}
      </main>
    </div>
  )
}

export default Applications
