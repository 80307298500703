import React from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router'
import { AppDispatch } from "../../../store"
import { checkAppAndAction } from '../../Applications/apps'
import { setShareData } from '../redux/shareDataSlice'

function SaveShareDataAuthMiddleware({ children }: { children: any }) {
  const { token, app } = useParams()
  const dispatch:AppDispatch = useDispatch()

  if (token && checkAppAndAction(app, "auth")) {
    dispatch(setShareData({ token, app: app || "", action: "auth" }))
  }
  
  return children
}

export default SaveShareDataAuthMiddleware
