import React from 'react'
import ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'
import App from './App'
import { persistor, store } from './store'
import { PersistGate } from 'redux-persist/integration/react'
import './i18n'
import { ConfigProvider } from 'antd'
import 'antd/dist/antd.variable.min.css'
import './index.scss'

ConfigProvider.config({ theme: { primaryColor: "#1d495b" } })


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
)

root.render(
  <ConfigProvider>
    <Provider store={store}>
      <PersistGate loading={<div>Loading...</div>} persistor={persistor}>
        <App />
      </PersistGate>
    </Provider>
  </ConfigProvider>
)
