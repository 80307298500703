import { createSlice } from '@reduxjs/toolkit'

interface State {
  drawerOpened: boolean
}


const initialState:State = {
  drawerOpened: false,
}


const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    openDrawer: (state) => {
      state.drawerOpened = true
    },
    closeDrawer: (state) => {
      state.drawerOpened = false
    },
  },
})


export default appSlice.reducer


export const {
  openDrawer,
  closeDrawer,
} = appSlice.actions
