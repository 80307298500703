import TemmaMedecinIcon from "./resources/temma-medecin.svg"
import TemmaOperatorIcon from "./resources/temma-operator.svg"
import TemmaPatientIcon from "./resources/temma-patient.svg"
import SovereignConferencingIcon from "./resources/sovereign-conferencing.svg"


interface AppActionFieldDefinition  {
  required: boolean,
  validated: boolean,
}


export interface AppActionDefinition {
  name: string,
  shareFields: { [key: string]: AppActionFieldDefinition },
  error_message_missed_fields: string,
}


export interface AppDefinition {
  name: string,
  icon: string,
  fields: string[],
  actions: { [key: string]: AppActionDefinition },
}


export const APPLICATIONS: { [key: string]: AppDefinition } = {
  "temma-medecin": {
    name: "Temma medecin",
    fields: ["email"],
    icon: TemmaMedecinIcon,
    actions: {
      auth: {
        name: 'Authentication',
        shareFields: {
          email: {
            required: true,
            validated: true,
          },
        },
        error_message_missed_fields: 'To connect to __APPLICATION__, the following fields are required: __FIELDS__',
      },
    },
  },
  "temma-operator": {
    name: "Temma operator",
    fields: ["email"],
    icon: TemmaOperatorIcon,
    actions: {
      auth: {
        name: 'Authentication',
        shareFields: {
          email: {
            required: true,
            validated: true,
          },
        },
        error_message_missed_fields: 'To connect to __APPLICATION__, the following fields are required: __FIELDS__',
      },
    },
  },
  "temma-patient": {
    name: "Temma patient",
    fields: ["email"],
    icon: TemmaPatientIcon,
    actions: {
      auth: {
        name: 'Authentication',
        shareFields: {
          email: {
            required: true,
            validated: true,
          },
        },
        error_message_missed_fields: 'To connect to __APPLICATION__, the following fields are required: __FIELDS__',
      },
    },
  },
  "sovereign-conferencing": {
    name: "Sovereign conferencing",
    fields: ['email'],
    icon: SovereignConferencingIcon,
    actions: {
      auth: {
        name: 'Authentication',
        shareFields: {
          email: {
            required: true,
            validated: true,
          },
        },
        error_message_missed_fields: 'To connect to __APPLICATION__, the following fields are required: __FIELDS__',
      },
    },
  },
}


export function checkAppAndAction(app?: string, action?: string) {
  return app && APPLICATIONS[app] && action && APPLICATIONS[app].actions[action]
}
