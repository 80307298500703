import React, { useEffect, useState } from 'react'
import { Form, Input, Button, notification, Tabs } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import { Link } from 'react-router-dom'
import { AppDispatch, RootState } from '../../store'
import { attemptSighUp, idleAttemptSighUpStatus } from '../Auth/redux/authSlice'
import { useTranslation } from 'react-i18next'
import { IoMdFingerPrint } from 'react-icons/io'
import { checkPlatformAuthenticatorAvailable } from '../../webauthn/webAuthnUtils'
import { attemptRegister, idleWebauthnRegisterStatus } from '../../webauthn/webAuthnSlice'
import "./SignUp.scss"
import { MdLogin } from 'react-icons/md'
import { LanguageSwitch } from '../LanguageSwitch/LanguageSwitch'
import FloatInput from '../../components/FloatInput'

const { TabPane } = Tabs


function SignUp() 
{
  const [form] = Form.useForm()
  const [platformAuthenticatorForm] = Form.useForm()
  const dispatch:AppDispatch = useDispatch()
  const { t } = useTranslation('signUp')
  const navigate = useNavigate()
  const user = useSelector((state:RootState) => state.auth.user)
  const attemptSighUpStatus = useSelector((state:RootState) => state.auth.attemptSighUpStatus)
  const webauthnRegisterStatus = useSelector((state:RootState) => state.webAuthn.webauthnRegisterStatus)
  const [platformAuthenticatorAvailable, setPlatformAuthenticatorAvailable] = useState(false)
  const [platformAuthenticatorEmail, setPlatformAuthenticatorEmail] = useState('')


  useEffect(() => {
    if (user) {
      navigate('/')
    }
  }, [user])


  useEffect(() => {
    checkPlatformAuthenticatorAvailable()
      .then(setPlatformAuthenticatorAvailable)

    return () => {
      dispatch(idleWebauthnRegisterStatus())
    }
  }, [])


  useEffect(() => {
    switch (webauthnRegisterStatus) {
      case 'idle':
      case "loading":
      case "success":
        break

      case "EMAIL_PASSWORD_ACCOUNT_ALREADY_EXIST":
        platformAuthenticatorForm.setFields([
          {
            name: 'email',
            errors: [t('EMAIL_PASSWORD_ACCOUNT_ALREADY_EXIST')],
          },
        ])
        break

      case "unknown_error":
        notification.open({
          type: 'error',
          message: t('Unexpected error'),
          description: t('An error occurred, please try later.'),
        })
        break

      default:
        notification.open({
          type: 'error',
          message: t('Unexpected error'),
          description: t(webauthnRegisterStatus),
        })
        break
    }
  }, [webauthnRegisterStatus])


  useEffect(() => {
    switch(attemptSighUpStatus) {
      case 'ACCOUNT_ALREADY_EXIST':
        form.setFields([
          {
            name: 'email',
            errors: [t('This email is already taken')],
          },
        ])
        break

      case 'FIDO2_ACCOUNT_ALREADY_EXIST':
        form.setFields([
          {
            name: 'email',
            errors: [t('FIDO2_ACCOUNT_ALREADY_EXIST')],
          },
        ])
        break

      case 'unknown_error':
        notification.open({
          type: 'error',
          message: 'Error',
          description: t('An error occurred, plase try later')
        })
        break
    }

    return () => {
      dispatch(idleAttemptSighUpStatus())
    }
  }, [attemptSighUpStatus])


  const onFinish = (values: { email: string, password: string }) => {
    dispatch(attemptSighUp(values))
  }


  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo)
  }


  function onPlatformAuthenticatorEmailChange(e: any) {
    setPlatformAuthenticatorEmail(e && e.target ? e.target.value : '')
  }


  function onPlatformAuthenticatorFormFinish(values: { email: string }) {
    dispatch(attemptRegister({ username: values.email }))
  }


  return (
    <div className="sign_up">
      <div className="background-gradient"></div>
      
      <img className="logo" src="/images/logo.png"/>
      <h3 className="logo-text">Secure & Access</h3>
      <h1 className="text-align-center">{t("Create account")}</h1>
      <div className="sign_up-container">
        <Tabs defaultActiveKey="1" tabBarExtraContent={<LanguageSwitch/>}>
          <TabPane tab={t('Email and password')} key="1">
            <Form
              form={form}
              name="basic"
              initialValues={{ email: '', password: '' }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
            >
              <Form.Item
                label={false}
                name="email"
                rules={[
                  { required: true, message: t('Please input your email') },
                  { type: 'email', message: t('Email is not valid') },
                ]}
              >
                <FloatInput
                  label={t("Email")}
                  name="email"
                  type="text"
                />
              </Form.Item>

              <Form.Item
                label={false}
                name="password"
                rules={[{ required: true, message: t('Please input your password') }]}
              >
                <FloatInput
                  label={t("Password")}
                  name="password"
                  type="password"
                />
              </Form.Item>

              <Form.Item>
                <Button type="primary" htmlType="submit" block loading={attemptSighUpStatus === "loading"}>
                  {t('Sign up')}
                </Button>
              </Form.Item>
            </Form>
          </TabPane>
          <TabPane tab={t('Biometric')} key="2" /*disabled={!platformAuthenticatorAvailable}*/>
            <Form
              form={platformAuthenticatorForm}
              name="basic"
              initialValues={{ email: '' }}
              onFinish={onPlatformAuthenticatorFormFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
            >
              <Form.Item
                label={false}
                name="email"
                rules={[
                  { required: true, message: t('Please input your email') },
                  { type: 'email', message: t('Email is not valid') },
                ]}
              >
                <FloatInput
                  label={t("Email")}
                  name="email"
                  type="text"
                  onChange={onPlatformAuthenticatorEmailChange}
                />
              </Form.Item>

              <Form.Item className="text-align-center">
                <Button
                  type="primary"
                  htmlType="submit"
                  shape="circle"
                  className="platform-authenticator-button"
                  icon={<IoMdFingerPrint size="4em" />}
                  disabled={!platformAuthenticatorEmail}
                />
              </Form.Item>
            </Form>
          </TabPane>
        </Tabs>

        <p className="sign_up-link_back">
          <Link to="/login"><MdLogin size="2em"/> <span>{t("If you already have an account, login here.")}</span></Link>
        </p>
      </div>
    </div>
  )
}

export default SignUp