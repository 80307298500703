import { useEffect } from "react"
import { BsFillPatchCheckFill } from "react-icons/bs"
import { VscError } from "react-icons/vsc"
import { ImSpinner9 } from "react-icons/im"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router-dom"
import { RootState, AppDispatch } from "../../store"
import { idleValidateEmailStatus, validateEmail } from "./redux/validationSlice"
import "./validation-email-page.scss"
import { Button } from "antd"


function ValidationEmail() {
  const { token } = useParams()
  const validateEmailStatus = useSelector((state:RootState) => state.validation.validateEmailStatus)
  const dispatch:AppDispatch = useDispatch()

  useEffect(() => {
    if (token) {
      dispatch(validateEmail({ token }))
    }

    return () => {
      dispatch(idleValidateEmailStatus())
    }
  }, [])


  return (
    <div className="validation_email_page">
      <div className="background-gradient"></div>
      <img className="logo" src="/images/logo.png"/>
      <h1>Secure & Access</h1>

      <div className="validation_email_result">
        {!token && <><VscError color="#ff4d4f"/>&nbsp;<span>Invalid validation token</span></>}
        {token && (
          (validateEmailStatus === "idle" && <><ImSpinner9 className="icon-spin"/>&nbsp;<span>Loading. Wait please.</span></>) || 
          (validateEmailStatus === "loading" && <><ImSpinner9 className="icon-spin"/>&nbsp;<span>Wait please, we are validating your email.</span></>) ||
          (validateEmailStatus === "success" && <><BsFillPatchCheckFill color="#52c41a"/>&nbsp;<span>Congratulations! Your email has been successfully validated.</span></>) ||
          (validateEmailStatus === "unknown_error" && <><VscError color="#ff4d4f"/>&nbsp;<span>Error. We could not validate your email.</span></>)
        )}
      </div>

      <Button href="/#/" type="default">Go to homepage</Button>
    </div>
  )
}

export default ValidationEmail
