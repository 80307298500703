import React from 'react'
import { Modal } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import { forgetPin } from '../../Auth/redux/authSlice'
import { AppDispatch, RootState } from '../../../store'
import ForgetPinIcon from './ForgetPin.svg'
import './ForgetPin.scss'


function ForgetPin() {
  const dispatch:AppDispatch = useDispatch()
  const { t } = useTranslation('authPin')
  const user = useSelector((state:RootState) => state.auth.user)

  function onForgetPin() {
    Modal.confirm({
      title: t('Do you really want to forget PIN code for this device?'),
      icon: <ExclamationCircleOutlined />,
      content: t('You will need to enter your email and password to create new PIN.'),
      okType: 'danger',
      onOk() {
        if (user) {
          dispatch(forgetPin(user.email))
        }
      }
    })
  }

  return (
    <img className="ForgetPin" src={ForgetPinIcon} onClick={onForgetPin}/>
  )
}

export default ForgetPin
