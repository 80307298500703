import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import { BsBackspace } from 'react-icons/bs'
import { notification } from 'antd'
import { AppDispatch, RootState } from '../../../store'
import { attemptAuthPin, wrongPinEntered } from '../../Auth/redux/authSlice'
import PinFeedback from '../CreatePin/PinFeedback'
import PinButton from '../CreatePin/PinButton'
import { useTranslation } from 'react-i18next'
import ForgetPin from '../ForgetPin/ForgetPin'
import './AuthPin.scss'


function AuthPin() {
  const navigate = useNavigate()
  const dispatch:AppDispatch = useDispatch()
  const [pin, setPin] = useState('')
  const { t } = useTranslation('authPin')
  const user = useSelector((state:RootState) => state.auth.user)
  const storedPin = useSelector((state:RootState) => state.auth.pins?.[user?.email || ''])

  
  useEffect(() => {
    if (user && storedPin && pin.length === 6) {
      if (pin == storedPin.pin) {
        dispatch(attemptAuthPin({ pin }))
        navigate('/')
      } else {
        setPin('')
        dispatch(wrongPinEntered(user.email))

        let description = storedPin.wrongTriesLeft > 1
          ? t('TRY_AGAIN', { pinWrongTriesLeft: storedPin.wrongTriesLeft - 1 })
          : t('WRONG_PIN_3_TIMES')
  
          notification.open({
            type: 'error',
            message: t('Wrong PIN'),
            description,
          })
      }
    }
  }, [pin])


  function addPinLetter(letter: string) {
    if (pin.length < 6) {
      setPin(pin + letter)
    }
  }

  
  function eraseLastLetter() {
    setPin(pin.slice(0, -1))
  }


  return (
    <>
      <div className="feedback-text">{t('Enter your PIN to access to your account')}</div>

      <PinFeedback full={pin.length}/>
      <div className="mt-2">
        <div className="text-align-center">
          <PinButton symbol="1" onClick={() => addPinLetter("1")}/>
          <PinButton symbol="2" onClick={() => addPinLetter("2")}/>
          <PinButton symbol="3" onClick={() => addPinLetter("3")}/>
        </div>
        <div className="text-align-center">
          <PinButton symbol="4" onClick={() => addPinLetter("4")}/>
          <PinButton symbol="5" onClick={() => addPinLetter("5")}/>
          <PinButton symbol="6" onClick={() => addPinLetter("6")}/>
        </div>
        <div className="text-align-center">
          <PinButton symbol="7" onClick={() => addPinLetter("7")}/>
          <PinButton symbol="8" onClick={() => addPinLetter("8")}/>
          <PinButton symbol="9" onClick={() => addPinLetter("9")}/>
        </div>
        <div className="text-align-center">
          <ForgetPin/>
          <PinButton symbol="0" onClick={() => addPinLetter("0")}/>
          <PinButton symbol={<BsBackspace/>} onClick={eraseLastLetter}/>
        </div>
      </div>
    </>
  )
}

export default AuthPin
