import React from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router'
import { AppDispatch } from "../../../store"
import { checkAppAndAction } from '../../Applications/apps'
import { setShareData } from '../redux/shareDataSlice'

function SaveShareDataMiddleware({ children }: { children: any }) {
  const { token, app, action } = useParams()
  const dispatch:AppDispatch = useDispatch()

  if (token && checkAppAndAction(app, action)) {
    dispatch(setShareData({ token, app: app || "", action: action || "" }))
  }
  
  return children
}

export default SaveShareDataMiddleware
