import React, { useEffect, useRef, useState } from 'react'
import QrScanner from 'qr-scanner'
import './qr_scan.scss'
import { useNavigate } from 'react-router'
import { Link } from 'react-router-dom'
import { checkAppAndAction } from '../Applications/apps'
import { RiArrowGoBackLine } from 'react-icons/ri'

// QrScanner.WORKER_PATH = '/qr-scanner-worker.min.js'


interface ScanRegion {
  width: number,
  height: number,
  x: number,
  y: number,
}


function QRScan() {
  const SELF_ORIGIN = process.env.REACT_APP_SELF_ORIGIN
  const videoRef = useRef<HTMLVideoElement>(null)
  const navigate = useNavigate()
  let qrScanner: QrScanner | undefined
  let [scanRegionClient, setScanRegionClient] = useState<ScanRegion | null>(null)


  function onQRCodeDecoded(result: QrScanner.ScanResult) {
    if (!result) {
      return
    }

    const url = new URL(result.data)
    if (url.origin !== SELF_ORIGIN || new URL(document.URL).origin != SELF_ORIGIN) {
      console.error('Wrong Secure n Access origin: url.origin = ' + url.origin + '; SELF_ORIGIN=' + SELF_ORIGIN + '; document.URL=' + new URL(document.URL))
      return
    }

    // const url = new URL("https://safeaccess-dev.temma.care/share-data/8093d176-b558-4849-a4cc-4fcafaf3e2dd/temma-medecin/auth")
    const match = url.pathname.match(/\/share-data\/([0-9a-z\-]+)\/([a-z\-]+)\/([a-z\-]+)/)
    
    const token  = match ? match[1] : undefined
    const app    = match ? match[2] : undefined
    const action = match ? match[3] : undefined

    if (token && checkAppAndAction(app, action)) {
      navigate(`/share-data/${token}/${app}/${action}`)
    }
  }


  function calculateScanRegion(video: HTMLVideoElement) {
    if (video.clientHeight <= 0 || video.clientWidth <= 0) {
      return { }
    }

    const scanRegionClientSide = Math.min(video.clientHeight, video.clientWidth) * 2 / 3

    const clientRatio = video.clientWidth / video.clientHeight
    const videoRatio = video.videoWidth / video.videoHeight

    let k
    if (clientRatio < videoRatio) {
      // same height
      k = video.videoHeight / video.clientHeight
    } else {
      // same width
      k = video.videoWidth / video.clientWidth
    }

    const scanRegionSide = scanRegionClientSide * k

    setScanRegionClient({
      width: scanRegionClientSide,
      height: scanRegionClientSide,
      x: (video.clientWidth - scanRegionClientSide) / 2,
      y: (video.clientHeight - scanRegionClientSide) / 2,
    })

    const scanRegion = {
      x: (video.videoWidth - scanRegionSide) / 2,
      y: (video.videoHeight - scanRegionSide) / 2,
      width: scanRegionSide,
      height: scanRegionSide
    }

    return scanRegion
  }


  function restartScanner(video: HTMLVideoElement) {
    if (qrScanner) {
      qrScanner.destroy()
    }

    qrScanner = new QrScanner(
      video,
      onQRCodeDecoded,
      {
        calculateScanRegion,
      }
    )

    qrScanner.start()
  }


  useEffect(() => {
    if (videoRef.current) {
      restartScanner(videoRef.current)
    }

    return () => {
      if (qrScanner) {
        qrScanner.stop()
      }
    }
  }, [])


  function resize() {
    if (videoRef.current) {
      // restartScanner(videoRef.current)
    }
  }


  useEffect(() => {
    window.addEventListener("resize", resize)
    window.testurl = (url: string) => {
      onQRCodeDecoded({data: url} as QrScanner.ScanResult)
    }

    return () => {
      window.removeEventListener("resize", resize)
    }
  }, [])


  function overlay(scanRegion: ScanRegion | null) {
    if (scanRegion) {
      return (
        <div className="overlay">
          <div className="overlay_center" style={{
            left: scanRegion.x - 2000,
            top: scanRegion.y - 2000,
            width: scanRegion.width,
            height: scanRegion.height,
          }}>
            <div className="line"></div>
          </div>
        </div>
      )
    }

    return null
  }


  return (
    <div>
      <div className="qrscan-header">
        <Link to="/" style={{ display: "flex" }}><RiArrowGoBackLine size="3em" color="#ffffff" className="header-icon"/></Link>
        <div className="header-center">
          <img className="logo" src="/images/logo.png"/>
          <span className="logo-text">Secure & Access</span>
        </div>
        <div></div>
      </div>

      <video ref={videoRef} id="qr-scanner-video"/>
      {overlay(scanRegionClient)}
    </div>
  )
}

export default QRScan
