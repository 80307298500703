import { createSlice, PayloadAction } from "@reduxjs/toolkit"


interface State {
  email?: string,
}


const initialState:State = {
  
}


const fieldsSlice = createSlice({
  name: 'fields',
  initialState,
  reducers: {
    setFieldEmail: (state, action: PayloadAction<string | undefined>) => {
      state.email = action.payload
    },
    resetFields: (state) => {
      state.email = undefined
    }
  },
})


export default fieldsSlice.reducer


export const {
  setFieldEmail,
} = fieldsSlice.actions
