import React from 'react'
import './PinFeedback.scss'


function PinFeedback(props: { full: number }) {
  return (
    <div className="pin-feedback">
      <div className={props.full >= 1 ? "full": ""}></div>
      <div className={props.full >= 2 ? "full": ""}></div>
      <div className={props.full >= 3 ? "full": ""}></div>
      <div className={props.full >= 4 ? "full": ""}></div>
      <div className={props.full >= 5 ? "full": ""}></div>
      <div className={props.full >= 6 ? "full": ""}></div>
    </div>
  )
}

export default PinFeedback