import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Navigate, useNavigate, useParams } from 'react-router'
import { AppDispatch, RootState } from '../../../store'
import { idleAcceptShareDataStatus, acceptShareData, emptyShareData } from '../redux/shareDataSlice'
import { APPLICATIONS, checkAppAndAction } from '../../Applications/apps'
import { Button, notification } from 'antd'
import { BiError } from 'react-icons/bi'
import { attemptGetApplicationData, idleAttemptGetApplicationDataStatus } from '../../Applications/redux/applicationsSlice'
import ShareDataComponent from '../ShareDataComponent/ShareDataComponent'
import "../ShareData.scss"


function ShareDataActionDefault() {
  const { token, app, action } = useParams()
  const dispatch:AppDispatch = useDispatch()
  const navigate = useNavigate()
  const acceptShareDataStatus = useSelector((state:RootState) => state.shareData.acceptShareDataStatus)
  
  const appData = useSelector((state:RootState) => state.applications.data ? state.applications.data[app || ''] : '')
  let appParsedData:{[field:string]: string}|undefined
  try {
    appParsedData = JSON.parse(appData)
  } catch(e) {}

  const appDefinition = APPLICATIONS[app || ""]
  const actionDefinition = appDefinition?.actions[action || ""]


  useEffect(() => {
    dispatch(attemptGetApplicationData({ name: app || '' }))

    return () => {
      dispatch(emptyShareData())
      dispatch(idleAttemptGetApplicationDataStatus())
    }
  }, [])


  useEffect(() => {
    switch (acceptShareDataStatus) {
      case 'success':
        notification.open({
          type: 'success',
          message: "Success",
          description: 'Successfully shared your data.',
        })
        navigate('/')
        break
        
      case 'unknown_error':
      case 'UNKNOWN_TOKEN':
      case 'CLIENT_DISCONNECTED':
        notification.open({
          type: 'error',
          message: 'Unexpected error',
          description: 'Could not share data. Please try later.',
        })
        navigate('/')
        break

      case 'REQUIRED_DATA_MISSING':
        notification.open({
          type: 'error',
          message: 'Could not share data',
          description: 'You did not enter required data for this application.'
        })
        navigate(`/applications/${app}`)
        break
    }

    return () => {
      dispatch(idleAcceptShareDataStatus())
    }
  }, [acceptShareDataStatus])


  if (!app || !action || !appDefinition || !actionDefinition) {
    notification.open({
      type: 'error',
      message: 'Could not share data',
      description: 'Invalid application and action',
    })
    
    return <Navigate to="/"/>
  }


  function accept() {
    dispatch(acceptShareData({ token: token || "" }))
  }


  function decline() {
    dispatch(emptyShareData())
    navigate('/')
  }


  return (
    <div className="share_data_page">
      <div className="background-gradient"></div>
      <img className="logo" src="/images/logo.png"/>
      <h1>Secure & Access</h1>
      <div className="share_data_panel">
        {token && checkAppAndAction(app, action)
          ? <ShareDataComponent 
              app={app || ""}
              appDefinition={appDefinition}
              action={action || ""}
              actionDefinition={actionDefinition}
              accept={accept}
              decline={decline} />
          : <div className="share_data_invalid_container">
              <BiError color="#ff4d4f" size="4em"/>
              <div className="share_data_invalid_text">Invalid token</div>
              <Button href="/#/" type="default" className="mt-1">Go to homepage</Button>
            </div>
        }
      </div>
    </div>
  )
}

export default ShareDataActionDefault
