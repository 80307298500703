import React from 'react'
import { Dropdown, Menu } from 'antd'
import { useTranslation } from 'react-i18next'
import { GB, FR } from 'country-flag-icons/react/3x2'
import "./language-switch.scss"


export function LanguageSwitch() {
  const { i18n } = useTranslation([])

  function getIcon(language: string) {
    switch (language) {
      case "en":
        return <GB className="country-flag"/>
      case "fr":
        return <FR className="country-flag"/>
    }
  }

  const languages = (
    <Menu
      selectable
      onSelect={async (info) => {
        i18n.changeLanguage(info.key)
      }}
      selectedKeys={[i18n.language]}
      items={[
      {
        key: "en", label: <div>{getIcon("en")} <span>English</span></div>
      },
      {
        key: "fr", label: <div>{getIcon("fr")} <span>Français</span></div>
      },
    ]}/>
  )

  return (
    <Dropdown overlay={languages} trigger={["click"]}>
      <div>{getIcon(i18n.language)}</div>
    </Dropdown>
  )
}
