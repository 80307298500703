import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import Header from '../../components/Header/Header'
import Drawer from '../../components/Drawer/Drawer'
import './index.scss'


function Index()
{
  const { t } = useTranslation('index')

  return (
    <>
    
    <div className="index">
      <Header/>
      <div className="background-gradient"/>
      <main className="main">
        <div className="menu-row">
          <Link to="/qr-scan" className="menu-item">
            <div className="menu-item-image">
              <img src="/images/qr-code.svg" />
            </div>
            <div className="menu-item-text">{t('Scan QR-code')}</div>
          </Link>
        </div>
        <div className="menu-row">
          <Link to="/personal-information" className="menu-item">
            <div className="menu-item-image">
              <img src="/images/personal-information.svg" />
            </div>
            <div className="menu-item-text">{t("My personal information")}</div>
          </Link>
          <Link to="/applications" className="menu-item">
            <div className="menu-item-image">
              <img src="/images/application.svg" />
            </div>
            <div className="menu-item-text">{t("Applications")}</div>
          </Link>
        </div>
      </main>
    </div>
    <Drawer/>
    </>
  )
}

export default Index