import React from 'react'
import { useSelector } from "react-redux"
import { Navigate } from "react-router"
import { RootState } from "../../store"

function RequireAuthMiddleware({ children }: { children: any }) {
  const user = useSelector((state: RootState) => state.auth.user)

  if (!user) {
    return <Navigate to="/sign-up" replace />
  }

  return children
}

export default RequireAuthMiddleware
