import React from 'react'
import {
  BrowserRouter,
  HashRouter,
  Routes,
  Route
} from 'react-router-dom'
import Application from './screens/Applications/Application'
import Applications from './screens/Applications/Applications'
import Auth from './screens/Auth/Auth'
import RequireAuthMiddleware from './screens/Auth/RequireAuthMiddleware'
import RequireSecondFactorAuthMiddleware from './screens/Auth/RequireSecondFactorAuthMiddleware'
import Index from './screens/Index/Index'
import PersonalInformation from './screens/PersonalInformation/PersonalInformation'
import SignUp from './screens/SignUp/SignUp'
import QRScan from './screens/QRScan/QRScan'
import ValidationEmail from './screens/Validation/ValidationEmail'
import ShareDataActionDefault from './screens/ShareData/ActionDefault/ShareDataActionDefault'
import ReinitPassword from './screens/ReinitPassword/ReinitPassword'
import ShareDataMiddleware from './screens/ShareData/ShareDataMiddleware'
import SaveShareDataAuthMiddleware from './screens/ShareData/ActionAuth/SaveShareDataAuthMiddleware'
import SaveShareDataMiddleware from './screens/ShareData/ActionDefault/SaveShareDataMiddleware'
import AuthCreateSecondFactor from './screens/AuthCreateSecondFactor/AuthCreateSecondFactor'
import ShareDataActionAuth from './screens/ShareData/ActionAuth/ShareDataActionAuth'


function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/login" element={<Auth />} />
          <Route path="/sign-up" element={<SignUp />} />
          <Route path="/second-factor" element={<RequireAuthMiddleware><AuthCreateSecondFactor /></RequireAuthMiddleware>} />
          <Route path="/" element={
            <RequireAuthMiddleware>
              <RequireSecondFactorAuthMiddleware>
                <ShareDataMiddleware>
                  <Index />
                </ShareDataMiddleware>
              </RequireSecondFactorAuthMiddleware>
            </RequireAuthMiddleware>}
          />
          <Route path="/personal-information" element={
            <RequireAuthMiddleware>
              <RequireSecondFactorAuthMiddleware>
                <ShareDataMiddleware>
                  <PersonalInformation />
                </ShareDataMiddleware>
              </RequireSecondFactorAuthMiddleware>
            </RequireAuthMiddleware>}
          />
          <Route path="/applications" element={
            <RequireAuthMiddleware>
              <RequireSecondFactorAuthMiddleware>
                <ShareDataMiddleware>
                  <Applications />
                </ShareDataMiddleware>
              </RequireSecondFactorAuthMiddleware>
            </RequireAuthMiddleware>}
          />
          <Route path="/applications/:id" element={
            <RequireAuthMiddleware>
              <RequireSecondFactorAuthMiddleware>
                <ShareDataMiddleware>
                  <Application />
                </ShareDataMiddleware>
              </RequireSecondFactorAuthMiddleware>
            </RequireAuthMiddleware>}
          />
          <Route path="/qr-scan" element={
            <RequireAuthMiddleware>
              <RequireSecondFactorAuthMiddleware>
                <ShareDataMiddleware>
                  <QRScan />
                </ShareDataMiddleware>
              </RequireSecondFactorAuthMiddleware>
            </RequireAuthMiddleware>}
          />
          <Route path="/share-data/:token/:app/auth" element={
            <SaveShareDataAuthMiddleware>
              <RequireAuthMiddleware>
                <RequireSecondFactorAuthMiddleware>
                  <ShareDataActionAuth />
                </RequireSecondFactorAuthMiddleware>
              </RequireAuthMiddleware>
            </SaveShareDataAuthMiddleware>}
          />
          <Route path="/share-data/:token/:app/:action" element={
            <SaveShareDataMiddleware>
              <RequireAuthMiddleware>
                <RequireSecondFactorAuthMiddleware>
                  <ShareDataActionDefault />
                </RequireSecondFactorAuthMiddleware>
              </RequireAuthMiddleware>
            </SaveShareDataMiddleware>}
          />
          <Route path="/validate/email/:token" element={<ValidationEmail />} />
          <Route path="/reinit-password/:token" element={<ReinitPassword />} />
        </Routes>
      </BrowserRouter>
    </div>
  )
}

export default App
