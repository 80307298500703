import React, { useState } from "react";
import { Input, InputProps } from "antd"
import "./index.scss"


interface Props extends InputProps {
  label: string
}


const FloatInput = (props: Props) => {
  const [ focus, setFocus ] = useState(false)
  let { label, value, placeholder, required } = props

  if (!placeholder) {
    placeholder = label
  }

  const isOccupied = focus || (value && typeof value === "string" && value.length !== 0)
  const labelClass = isOccupied ? "label as-label" : "label as-placeholder"
  const requiredMark = required ? <span className="text-danger">*</span> : null

  return (
    <div
      className="float-label"
      onBlur={() => setFocus(false)}
      onFocus={() => setFocus(true)}
    >
      {props.type === "text" && <Input {...props}/>}
      {props.type === "password" && <Input.Password {...props}/>}
      
      <label className={labelClass}>
        {isOccupied ? label : placeholder} {requiredMark}
      </label>
    </div>
  )
}

export default FloatInput
