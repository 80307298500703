import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import { Form, Input, Button, notification, Modal, Tabs } from 'antd'
import { attemptAuth, attemptReinitPassword, idleAttemptAuthStatus, idleAttemptReinitPasswordStatus } from './redux/authSlice'
import { AppDispatch, RootState } from '../../store'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { IoMdFingerPrint } from 'react-icons/io'
import { LanguageSwitch } from '../LanguageSwitch/LanguageSwitch'
import './Auth.scss'
import { checkPlatformAuthenticatorAvailable } from '../../webauthn/webAuthnUtils'
import { attemptWebauthnAuthenticate, idleWebauthnAuthenticateStatus } from '../../webauthn/webAuthnSlice'
import FloatInput from '../../components/FloatInput'

const { TabPane } = Tabs


function Auth()
{
  const [form] = Form.useForm()
  const [platformAuthenticatorForm] = Form.useForm()
  const [forgotPasswordForm] = Form.useForm()
  const [forgotPasswordFormVisible, setForgotPasswordFormVisible] = useState(false);

  const dispatch:AppDispatch = useDispatch()
  const navigate = useNavigate()
  const { t } = useTranslation('auth')
  const user = useSelector((state:RootState) => state.auth.user)
  const attemptAuthStatus = useSelector((state:RootState) => state.auth.attemptAuthStatus)
  const webauthnAuthenticateStatus = useSelector((state:RootState) => state.webAuthn.webauthnAuthenticateStatus)
  const attemptReinitPasswordStatus = useSelector((state:RootState) => state.auth.attemptReinitPasswordStatus)
  const [platformAuthenticatorAvailable, setPlatformAuthenticatorAvailable] = useState(false)
  const [platformAuthenticatorEmail, setPlatformAuthenticatorEmail] = useState('')


  useEffect(() => {
    if (user) {
      navigate('/')
    }
  }, [user])


  useEffect(() => {
    checkPlatformAuthenticatorAvailable()
      .then(setPlatformAuthenticatorAvailable)
  }, [])


  useEffect(() => {
    switch (webauthnAuthenticateStatus) {
      case "unknown_error":
        notification.open({
          type: 'error',
          message: t('Unexpected error'),
          description: t('An error occurred, please try later.'),
        })
        break
    }

    return () => {
      dispatch(idleWebauthnAuthenticateStatus())
    }
  }, [webauthnAuthenticateStatus])


  useEffect(() => {
    console.log(attemptAuthStatus)
    switch(attemptAuthStatus) {
      case 'Invalid credentials':
        form.setFields([
          {
            name: 'email',
            errors: [t('Invalid credentials')],
          },
          {
            name: 'password',
            errors: [t('Invalid credentials')],
          },
        ])
        break
      case 'unknown_error':
        notification.open({
          type: 'error',
          message: t('Unexpected error'),
          description: t('An error occurred, please try later.'),
        })
        break
    }
  }, [attemptAuthStatus])


  useEffect(() => {
    switch(attemptReinitPasswordStatus) {
      case 'success':
        setForgotPasswordFormVisible(false)
        notification.open({
          type: 'success',
          message: t('Success'),
          description: t('If your email is registered in your system you will receive an email to initialize your password.')
        })
        break

      case 'unknown_error':
        setForgotPasswordFormVisible(false)
        notification.open({
          type: 'error',
          message: t('Unexpected error'),
          description: t('An error occurred, please try later.'),
        })
        break
    }
  }, [attemptReinitPasswordStatus])


  useEffect(() => {
    return () => {
      dispatch(idleAttemptAuthStatus())
      dispatch(idleAttemptReinitPasswordStatus())
    }
  }, [])


  const onFinish = (values: { email: string, password: string }) => {
    dispatch(attemptAuth(values))
  }


  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo)
  }


  const submitForgotPassword = (values: { email: string }) => {
    dispatch(attemptReinitPassword(values))
  }


  function onPlatformAuthenticatorEmailChange(e: any) {
    setPlatformAuthenticatorEmail(e && e.target ? e.target.value : '')
  }


  function onPlatformAuthenticatorFormFinish(values: { email: string }) {
    dispatch(attemptWebauthnAuthenticate({ username: values.email }))
  }


  return (
    <>
      <div className="auth-page">
        <div className="background-gradient"></div>
        <img className="logo" src="/images/logo.png"/>
        <h3 className="logo-text">Secure & Access</h3>
        <h1 className="text-align-center">{t("Log in")}</h1>
        <div className="auth-form">
          <Tabs defaultActiveKey="1" tabBarExtraContent={<LanguageSwitch/>}>
            <TabPane tab={t('Email and password')} key="1">
              <Form
                form={form}
                name="login"
                initialValues={{ email: '', password: '' }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
              >
                <Form.Item
                  label={false}
                  name="email"
                  rules={[
                    { required: true, message: t('Please input your email') },
                    { type: 'email', message: t('Email is not valid') },
                  ]}
                >
                  <FloatInput
                    label={t("Email")}
                    name="email"
                    type="text"
                  />
                </Form.Item>

                <Form.Item
                  label={false}
                  name="password"
                  rules={[{ required: true, message: t('Please input your password') }]}
                >
                  <FloatInput
                    label={t("Password")}
                    name="password"
                    type="password"
                  />
                </Form.Item>

                <Form.Item className="mb-1">
                  <Button type="primary" htmlType="submit" block loading={attemptAuthStatus === "loading"}>
                    {t("Login")}
                  </Button>

                  <div className="forgot-password-link" onClick={() => setForgotPasswordFormVisible(true)}>{t("Forgot password?")}</div>
                </Form.Item>
              </Form>
            </TabPane>
            <TabPane tab={t('Biometric')} key="2">
              <Form
                form={platformAuthenticatorForm}
                name="basic"
                initialValues={{ email: '' }}
                onFinish={onPlatformAuthenticatorFormFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
              >
                <Form.Item
                  label={false}
                  name="email"
                  rules={[
                    { required: true, message: t('Please input your email') },
                    { type: 'email', message: t('Email is not valid') },
                  ]}
                >
                  <FloatInput
                    label={t("Email")}
                    name="email"
                    type="text"
                    onChange={onPlatformAuthenticatorEmailChange}
                  />
                </Form.Item>

                <div className="text-align-center mb-1">
                  <Button
                    type="primary"
                    htmlType="submit"
                    shape="circle"
                    className="platform-authenticator-button"
                    icon={<IoMdFingerPrint size="4em" />}
                    disabled={!platformAuthenticatorEmail}
                  />
                </div>
              </Form>
            </TabPane>
          </Tabs>
          <div className="create-account-link">
            <span><Link to="/sign-up">{t("Click here to create your account")}</Link> {t("and benefit from secure access")}.</span>
          </div>
        </div>  
      </div>

      <Modal title={t("Forgot password")} 
        visible={forgotPasswordFormVisible} 
        onOk={() => forgotPasswordForm.submit()}
        onCancel={() => setForgotPasswordFormVisible(false)}
        okButtonProps={{ loading: attemptReinitPasswordStatus === "loading" }}
      >
        <Form 
          className="mt-1"
          name="forgot_password"
          form={forgotPasswordForm}
          onFinish={submitForgotPassword}
        >
          <Form.Item
            label="Email"
            name="email"
            rules={[{ required: true, message: t('Please input your email') }]}
          >
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    </>
  )
}

export default Auth
