import React from 'react'
import { AiOutlinePoweroff } from 'react-icons/ai'
import { FaCog } from 'react-icons/fa'
import { useDispatch } from 'react-redux'
import { AppDispatch } from '../../store'
import { attemptLogoutPin } from '../../screens/Auth/redux/authSlice'
import { LanguageSwitch } from '../../screens/LanguageSwitch/LanguageSwitch'
import "./header.scss"
import { openDrawer } from '../../appSlice'


export interface HeaderProps {
  leftItem?: React.ReactNode;
}


function Header(props: HeaderProps)
{
  const dispatch:AppDispatch = useDispatch()
  
  function onLogout() {
    dispatch(attemptLogoutPin())
  }


  return (
    <div className="header">
      {props.leftItem || 
        <div className="header-left-item">
          <FaCog size="3em" color="#ffffff" className="header-icon" onClick={() => dispatch(openDrawer())} />
        </div>
      }
      <div className="header-center">
        <img className="logo" src="/images/logo.png"/>
        <span className="logo-text">Secure & Access</span>
      </div>
      <AiOutlinePoweroff size="3em" color="#ffffff" className="header-icon" onClick={onLogout}/>
    </div>
  )
}

export default Header
