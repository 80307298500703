import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";


interface State {
  validateEmailStatus: string,
}


const initialState:State = {
  validateEmailStatus: "idle",
}


export const validateEmail = createAsyncThunk(
  'validate/Email',
  async function({ token }: { token: string }) {
    const response = await fetch(
      process.env.REACT_APP_RELAY_BASE_URL + `/validate/email/${token}`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
      }
    )

    if (!response.ok) {
      throw new Error("unknown_error")
    }
  }
)


const validationSlice = createSlice({
  name: "validation",
  initialState,
  reducers: {
    idleValidateEmailStatus: (state) => {
      state.validateEmailStatus = "idle"
    }
  },
  extraReducers: {
    [validateEmail.pending.type]: (state, action) => {
      state.validateEmailStatus = "loading"
    },
    [validateEmail.fulfilled.type]: (state, action) => {
      state.validateEmailStatus = "success"
    },
    [validateEmail.rejected.type]: (state, action) => {
      const error = action.error
      state.validateEmailStatus = error.name === "ApiError" ? error.message : "unknown_error"
    },
  }
})


export default validationSlice.reducer


export const {
  idleValidateEmailStatus,
} = validationSlice.actions
