import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, notification } from 'antd'
import { AppDispatch, RootState } from '../../../store'
import ShareDataComponent from '../ShareDataComponent/ShareDataComponent'
import { Trans, useTranslation } from 'react-i18next'
import { APPLICATIONS } from '../../Applications/apps'
import { useNavigate, useParams } from 'react-router'
import { acceptShareData, emptyShareData, idleAcceptShareDataStatus } from '../redux/shareDataSlice'
import { attemptGetApplicationData, idleAttemptGetApplicationDataStatus, idleAttemptValidateDataStatus } from '../../Applications/redux/applicationsSlice'
import { ImSpinner9 } from 'react-icons/im'
import "../ShareData.scss"


function ShareDataActionAuth() {
  const { token, app } = useParams()
  const dispatch:AppDispatch = useDispatch()
  const navigate = useNavigate()
  const { t } = useTranslation('shareData')
  const appDefinition = APPLICATIONS[app || ""]
  const actionDefinition = appDefinition?.actions["auth"]
  const [validationSent, setValidationSent] = useState<string>('')
  const validatedEmails = useSelector((state:RootState) => state.applications.validatedEmails)
  const appData = useSelector((state:RootState) => state.applications.data ? state.applications.data[app || ''] : '')
  const acceptShareDataStatus = useSelector((state:RootState) => state.shareData.acceptShareDataStatus)
  const attemptGetApplicationDataStatus = useSelector((state:RootState) => state.applications.attemptGetApplicationDataStatus)
  const [autoShareTried, setAuthShareTried] = useState(false)
  
  let appParsedData:{[field:string]: string}|undefined
  try {
    appParsedData = JSON.parse(appData)
  } catch(e) {}
  const emailValue = appParsedData ? appParsedData['email'] : undefined


  useEffect(() => {
    dispatch(attemptGetApplicationData({ name: app || '' }))

    return () => {
      dispatch(emptyShareData())
      dispatch(idleAttemptGetApplicationDataStatus())
    }
  }, [])


  useEffect(() => {
    switch(attemptGetApplicationDataStatus) {
      case "idle":
      case "loading":
        break

      case "success":
        if (appData) {
          if (emailValue && isEmailValidated(emailValue)) {
            accept()
          }
        }
        break

      default:
        notification.open({
          type: 'error',
          message: t('Unexpected error'),
          description: t('An error occurred, please try later.')
        })
    }
  }, [attemptGetApplicationDataStatus])


  useEffect(() => {
    switch (acceptShareDataStatus) {
      case 'success':
        notification.open({
          type: 'success',
          message: "Success",
          description: 'Successfully shared your data.',
        })
        navigate('/')
        break
        
      case 'unknown_error':
      case 'UNKNOWN_TOKEN':
      case 'CLIENT_DISCONNECTED':
        notification.open({
          type: 'error',
          message: t('Unexpected error'),
          description: t('Could not authenticate'),
        })
        navigate('/')
        break

      case 'REQUIRED_DATA_MISSING':
        notification.open({
          type: 'error',
          message: t('Could not authenticate'),
          description: t('You did not enter required data for this application.')
        })
        navigate(`/applications/${app}`)
        break

      case "EMAIL_NOT_VALIDATED":
        notification.open({
          type: 'error',
          message: t('Could not authenticate'),
          description: t('You did not validated required data for this application.')
        })
        break
    }

    return () => {
      dispatch(idleAcceptShareDataStatus())
    }
  }, [acceptShareDataStatus])


  function isEmailValidated(email: string): boolean {
    if (appDefinition.fields.includes('email') && validatedEmails) {
      const emailStatus = validatedEmails[email]
      return emailStatus === "validated"
    }

    return false
  }


  function onAuthAccept(values: any) {
    // Send validation requests
    if (!isEmailValidated(values['email'])) {
      setValidationSent(values['email'])
      dispatch(idleAttemptValidateDataStatus())
      return
    }
    
    accept()
  }


  function accept() {
    dispatch(acceptShareData({ token: token || "" }))
  }


  function decline() {
    dispatch(emptyShareData())
    navigate('/')
  }

  
  return (
    <div className="share_data_page">
      <div className="background-gradient"/>
      <img className="logo" src="/images/logo.png"/>
      <h1>Secure & Access</h1>
      <div className="share_data_panel">
        {attemptGetApplicationDataStatus === "loading" || acceptShareDataStatus === "loading"
          ? <div className="text-align-center">
              <ImSpinner9 size="3em" className="icon-spin mb-1"/>
              <h3>{t('Wait please. Your request is being processed.')}</h3>
            </div>
          : (validationSent
            ? <>
                <div>
                  <Trans 
                    i18nKey="email_validation_message"
                    ns="shareData" 
                    values={{ email: validationSent }}
                    components={{ bold: <strong /> }} />
                </div>
                <div className="text-align-center">
                  <Button type="link" onClick={() => setValidationSent('')}>{t('Change email')}</Button>
                </div>
                <div className="share_data_actions">
                  <Button danger onClick={decline}>{t('Abandon')}</Button>
                  <Button type="primary" onClick={accept}>{t('Continue')}</Button>
                </div>
              </>
            : <ShareDataComponent app={app || ""}
                appDefinition={appDefinition}
                action={"auth"}
                actionDefinition={actionDefinition}
                accept={onAuthAccept}
                decline={decline}
                shareMessage={
                  <Trans
                    i18nKey="ACTION_AUTH_MESSAGE"
                    ns="shareData"
                    values={{ application: appDefinition.name }}
                    components={{ bold: <strong /> }}
                  />
                }
                actionsElement={
                  <div className="share_data_actions">
                    <Button danger onClick={decline}>{t('Abandon')}</Button>
                    <Button type="primary" htmlType="submit">
                      {!emailValue || validatedEmails[emailValue] && validatedEmails[emailValue] === "validated"
                        ? t('Accept')
                        : t('Continue')
                      }
                    </Button>
                  </div>
                }
              />
          )
        }
      </div>
    </div>
    
  )
}

export default ShareDataActionAuth
