import React from 'react'
import { Button } from "antd"
import './pin-button.scss'


function PinButton(props: { symbol?: any, onClick?: any }) {
  return (
    props.symbol 
    ? <Button className="pin_button" shape="circle" type="default" onClick={props.onClick}
      style={{
        display: "inline-flex",
        height: "4rem",
        width: "4rem",
        margin: "0 1rem 2rem 1rem",
        verticalAlign: "middle",
        alignItems: "center",
        justifyContent: "center",
        fontSize: "2rem",
        backgroundColor: "transparent",
        color: "#ffffff",
        border: "1px solid #ffffff",
      }}
    >
      {props.symbol}
    </Button>
    : <div className="pin_empty_space"></div>
  )
}

export default PinButton
