import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import ApiError from '../../../errors/ApiError'
import { setJWT } from '../../Auth/redux/authSlice'


interface State {
  authReinitPasswordTokenStatus: string,
  changePasswordStatus: string,
}


const initialState:State = {
  authReinitPasswordTokenStatus: "idle",
  changePasswordStatus: "idle",
}


/**
 * Reinit password.
 */
export const authReinitPasswordToken = createAsyncThunk(
  'reinitPassword/authenticateReinitPasswordToken',
  async ({ token }: { token: string }, { dispatch }) => {

    const response = await fetch(process.env.REACT_APP_RELAY_BASE_URL + '/reinit-password', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ token }),
    })

    if (!response.ok) {
      const reason = (await response.json()).reason || "unknown_error"
      throw new ApiError(reason)
    }

    const body = await response.json()

    dispatch(setJWT(body.token))
  }
)


/**
 * Reinit password.
 */
export const changePassword = createAsyncThunk(
  'reinitPassword/changePassword',
  async ({ password }: { password: string }, { getState }) => {

    const { auth } = getState() as { auth: { user: { jwt: string } } }

    const response = await fetch(process.env.REACT_APP_RELAY_BASE_URL + '/change-password', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${auth.user.jwt}`,
      },
      body: JSON.stringify({ password }),
    })

    if (!response.ok) {
      const reason = (await response.json()).reason || "unknown_error"
      throw new ApiError(reason)
    }
  }
)



const reinitPasswordSlice = createSlice({
  name: 'reinit-password',
  initialState,
  reducers: {
    idleAuthReinitPasswordTokenStatus: (state) => {
      state.authReinitPasswordTokenStatus = "idle"
    },
    idleChangePasswordStatus: (state) => {
      state.changePasswordStatus = "idle"
    },
  },
  extraReducers: {
    [authReinitPasswordToken.pending.type]: (state, action) => {
      state.authReinitPasswordTokenStatus = "loading"
    },
    [authReinitPasswordToken.fulfilled.type]: (state, action) => {
      state.authReinitPasswordTokenStatus = "success"
    },
    [authReinitPasswordToken.rejected.type]: (state, action) => {
      const error = action.error
      state.authReinitPasswordTokenStatus = error.name === "ApiError" ? error.message : "unknown_error"
    },

    [changePassword.pending.type]: (state, action) => {
      state.changePasswordStatus = "loading"
    },
    [changePassword.fulfilled.type]: (state, action) => {
      state.changePasswordStatus = "success"
    },
    [changePassword.rejected.type]: (state, action) => {
      const error = action.error
      state.changePasswordStatus = error.name === "ApiError" ? error.message : "unknown_error"
    },
  },
})


export default reinitPasswordSlice.reducer


export const {
  idleAuthReinitPasswordTokenStatus,
  idleChangePasswordStatus,
} = reinitPasswordSlice.actions
