import i18n from "i18next"
import { initReactI18next } from "react-i18next"
import authEN from './locales/auth.en.json'
import authFR from './locales/auth.fr.json'
import signUpEN from './locales/sign-up.en.json'
import signUpFR from './locales/sign-up.fr.json'
import authPinEN from './locales/auth-pin.en.json'
import authPinFR from './locales/auth-pin.fr.json'
import authCreateSecondFactorEN from './locales/auth-create-second-factor.en.json'
import authCreateSecondFactorFR from './locales/auth-create-second-factor.fr.json'
import createPinEN from './locales/create-pin.en.json'
import createPinFR from './locales/create-pin.fr.json'
import indexEN from './locales/index.en.json'
import indexFR from './locales/index.fr.json'
import applicationEN from './locales/application.en.json'
import applicationFR from './locales/application.fr.json'
import shareDataEN from './locales/share-data.en.json'
import shareDataFR from './locales/share-data.fr.json'


export const resources = {
  en: {
    auth: authEN,
    signUp: signUpEN,
    authPin: authPinEN,
    createPin: createPinEN,
    authCreateSecondFactor: authCreateSecondFactorEN,
    index: indexEN,
    application: applicationEN,
    shareData: shareDataEN,
  },
  fr: {
    auth: authFR,
    signUp: signUpFR,
    authPin: authPinFR,
    createPin: createPinFR,
    authCreateSecondFactor: authCreateSecondFactorFR,
    index: indexFR,
    application: applicationFR,
    shareData: shareDataFR,
  },
} as const;


i18n
  .use(initReactI18next)
  .init({
    lng: 'fr',
    fallbackLng: 'en',
    ns: ['auth', 'signUp', 'authPin', 'createPin', 'authCreateSecondFactor', 'index', 'application', 'shareData'],
    resources,
  })
  
