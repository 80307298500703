import React, { useState } from "react";
import { AutoComplete, AutoCompleteProps, Input, InputProps } from "antd"
import "./index.scss"


interface Props extends AutoCompleteProps {
  label: string
}


const FloatInput = (props: Props) => {
  const [ focus, setFocus ] = useState(false)
  let { label, value, placeholder } = props

  if (!placeholder) {
    placeholder = label
  }

  const isOccupied = focus || (value && typeof value === "string" && value.length !== 0)
  const labelClass = isOccupied ? "label as-label" : "label as-placeholder"

  return (
    <div
      className="float-label"
      onBlur={() => setFocus(false)}
      onFocus={() => setFocus(true)}
    >
      <AutoComplete {...props}/>
      
      <label className={labelClass}>
        {isOccupied ? label : placeholder}
      </label>
    </div>
  )
}

export default FloatInput
