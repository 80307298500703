import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router'
import { AppDispatch, RootState } from '../../store'
import { authReinitPasswordToken, changePassword, idleAuthReinitPasswordTokenStatus, idleChangePasswordStatus } from './redux/reinitPasswordSlice'
import { Button, notification, Form, Input } from 'antd'
import { RiLockPasswordLine } from "react-icons/ri"
import { BiError } from 'react-icons/bi'
import "./reinit-password.scss"
import { ImSpinner9 } from 'react-icons/im'



function ReinitPassword() {
  const { token } = useParams()
  const dispatch:AppDispatch = useDispatch()
  const authReinitPasswordTokenStatus = useSelector((state:RootState) => state.reinitPassword.authReinitPasswordTokenStatus)
  const changePasswordStatus = useSelector((state:RootState) => state.reinitPassword.changePasswordStatus)
  const user = useSelector((state:RootState) => state.auth.user)
  const navigate = useNavigate()
  

  useEffect(() => {
    if (token) {
      dispatch(authReinitPasswordToken({ token }))
    }

    return () => {
      dispatch(idleAuthReinitPasswordTokenStatus())
    }
  }, [])


  useEffect(() => {
    switch(changePasswordStatus) {
      case "success":
        notification.open({
          type: 'success',
          message: 'Success',
          description: 'Your password has been successfully changed.'
        })
        navigate('/')
        break
      case "unknown_error":
        notification.open({
          type: 'error',
          message: 'Error',
          description: 'An error occurred, please try later.'
        })
        break
    }

    return () => {
      dispatch(idleChangePasswordStatus())
    }
  }, [changePasswordStatus])


  return (
    <div className="reinit_password_page">
      <div className="background-gradient"></div>
      <img className="logo" src="/images/logo.png"/>
      <h1>Secure & Access</h1>
      <div className="reinit_password_panel">
        {token
          ? <>
              <div className="reinit_password_title">
                <RiLockPasswordLine className="mr-1"/> Reinitialize password
              </div>

              <div>
                {authReinitPasswordTokenStatus === "unknown_error" && 
                  <div className="reinit_password_title_error_container">
                    <BiError color="#ff4d4f" size="4em"/>
                    <div>Invalid request</div>
                  </div>
                }
                {authReinitPasswordTokenStatus === "loading" && 
                  <div className="reinit_password_title_loading_container">
                    <ImSpinner9 size="4em" className="icon-spin"/>
                    <div>Wait please</div>
                  </div>
                }
                {user && 
                  <Form
                    name="reinit_password"
                    className="mt-1"
                    labelCol={{ span: 10 }}
                    wrapperCol={{ span: 14 }}
                    onFinish={({ password }: { password: string }) => dispatch(changePassword({ password }))}
                  >
                    <Form.Item
                      label="New password"
                      name="password"
                      rules={[{ required: true, message: 'Please input your password' }]}
                    >
                      <Input.Password />
                    </Form.Item>

                    <Form.Item
                      label="Confirm password"
                      name="confirmPassword"
                      dependencies={['password']}
                      rules={[
                        {
                          required: true,
                          message: 'Please confirm your password',
                        },
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            if (!value || getFieldValue('password') === value) {
                              return Promise.resolve()
                            }
                            return Promise.reject(new Error('The two passwords that you entered do not match'))
                          },
                        }),
                      ]}
                    >
                      <Input.Password />
                    </Form.Item>

                    <Form.Item
                      wrapperCol={{ xs: { }, sm: { offset: 10} }}
                    >
                      <Button type="primary" htmlType="submit" block loading={changePasswordStatus === "loading"}>
                        Change password
                      </Button>
                    </Form.Item>
                  </Form>
                }
              </div>
            </>
          : <>Invalid token</>
        }
      </div>
    </div>
  )
}

export default ReinitPassword
